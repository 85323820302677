$(document).ready(function () {
	//FACEBOOK
	/*  $('.share-navigation .nav-social a').click(function(){
	 event.preventDefault();
	 console.log('0ria');
	 });*/

	/*$('.share.facebook').click(function () {
		event.preventDefault();
		window.open("https://www.facebook.com/sharer/sharer.php?u=" + escape(window.location.href) + "&t=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
		return false;
	});
	//TWITTER
	$('.share.twitter').click(function () {
		event.preventDefault();
		{
			window.open("https://twitter.com/share?url=" + escape(window.location.href) + "&text=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
			return false;
		}
	});

	// PINTEREST
	$('.share.pinterest').find("a").attr("href", "https://de.pinterest.com/pin/create/button/" + "data-pin-do", "buttonBookmark" + "data-pin-tall", "true" + "data-pin-lang", "de" + "data-pin-round", "true" + "data-pin-save", "false");

	// GOOGLE
	$('.share.google').click(function () {
		event.preventDefault();
		console.log(url = "+escape(window.location.href)+");
		{
			window.open("https://plus.google.com/share?url=" + escape(window.location.href) + "&text=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
			return false;
		}
	});*/

	$('.share.facebook').click(function () {
		event.preventDefault();
		window.open("https://www.facebook.com/sharer/sharer.php?u=" + escape(window.location.href) + "&t=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
		return false;
	});

	$('.share.xing').click(function () {
		event.preventDefault();
		window.open("https://www.xing.com/spi/shares/new?url=" + escape(window.location.href) + "&text=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
		return false;
	});

	$('.share.linkedin').click(function () {
		event.preventDefault();
		window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + escape(window.location.href) + "&text=" + document.title, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
		return false;
	});

	// WHATSAPP
	//$('.share.whatsapp').find("a").attr("href", "whatsapp://send?text=" + escape(window.location.href) + " " + document.title);
	$('.share.whatsapp').find("a").attr("href", "https://wa.me/xxxxxxxx");

	$('.share.email').find("a").attr("href", "mailto:?body=" + escape(window.location.href));


});
