////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////// D) MULTISELECT JS
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//  MULTISELECT FUNCTIONALITY

$(document).ready(function($) {

	$("select.multiselect").each(function(index, element) {

		var $el = $(this),
			$elSettings = $el.parent().find('span.additional-multicheckbox-settings'),
			$elMsg = $el.parent().find('div.controls'),
			$warning = $el.find(".message"),
			currentValidateRequired = '',
			currentValidateErrMin ='',
			currentValidateErrMax = '',
			currentSelectedText = (  $el.data('selected-text') !== undefined ) ? $el.data('selected-text') : '{selected-text text}',
			currentNoneSelectedText = ( $el.data('none-selected-text')  !== undefined) ? $el.data('none-selected-text') : '{none-selected-text text}',
			currentMaxValues = ( $el.data('validate-max-values')  !== undefined) ? $el.data('validate-max-values') : 999;


		//	READ EXTRA DATA IF AVAILBALE

		if( $elSettings.length > 0 ) {
			currentSelectedText = $elSettings.data('selected-text'),
			currentNoneSelectedText = $elSettings.data('none-selected-text'),
			currentMaxValues = $elSettings.data('validate-max-values'),
			currentValidateRequired = $elSettings.data('validate-required'),
			currentValidateErrMin = $elSettings.data('validate-err-min'),
			currentValidateErrMax = $elSettings.data('validate-err-max');
			if(currentMaxValues) currentValidateErrMax=currentValidateErrMax.replace('#',currentMaxValues);
		}

		if(currentValidateRequired==true){
			$(this).multiselect({
				selectedList:1,
				header: false,
				selectedText: currentSelectedText,
				noneSelectedText: currentNoneSelectedText,
				click: function(e){

					if( $(this).multiselect("widget").find("input:checked").length > currentMaxValues ){
						alert_message(currentValidateErrMax,'prompt');
						return false;
					}else if( $(this).multiselect("widget").find("input:checked").length <=0 ) {
						$elMsg.parent('.control-group').addClass('error');
						$elMsg.html('<div class="alert alert-error"><h4>Fehler</h4>' + currentValidateErrMin + '</div>');
					} else {
						$elMsg.parent('.control-group').removeClass('error');
						$elMsg.html('');
					}
				}
			});
		}else{
			$(this).multiselect({
				selectedList:1,
				header: false,
				selectedText: currentSelectedText,
				noneSelectedText: currentNoneSelectedText,
				click: function(e){
					if( $(this).multiselect("widget").find("input:checked").length > currentMaxValues ){
						alert_message(currentValidateErrMax,'prompt');
						return false;
					} else {
						$elMsg.parent('.control-group').removeClass('error');
						$elMsg.html('');
					}
				}
			});
		}
	});


	var alert_message = function(msg,msgType){
		msg = msg || 'msg not defined in function alert_message()';
		switch (msgType) {
			case 'prompt':
				alert(msg);
				break;
			case 'message':
				//	TODO EMBED MESSAGE-TYPE 2, ADD ELEMENT AS WELL WHERE THE MESSAGE NEEDS TO BE ATTACHED
				break;
			default:
				cl(msg);
		}
	};
});